import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const GuitarBody = () => {

  return (
    <PageLayout>
      <SEO 
          title="Guitar Body Designs | Your Custom Build | King Electric Guitars"
          ogDescription="There are many options for guitar body designs when building your custom guitar. Learn about my T-style, S-style, LP-style, and Offset guitar body options. Let's build your dream guitar!" 
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>
              Guitar Body Designs
            </h1>
            <p>
              I try to build guitars that are familiar looking, but different. People don't want you to go too far outside of the box, but I will not make a a direct copy. I do my own thing. I make guitar designs that are familiar. Something that looks like what you're used to, but it's just got my own thing to it that you won't find anywhere else. A slightly offset body, slightly oversized or undersized to better fit the player. More contouring for ease of play and comfort. My focus is on building the perfect custom guitar to fit you and your style.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/guitar-body-designs.jpg"
              alt="Guitar Body Designs - King Electric Guitars"
            />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/guitar-body-shapes.jpg"
                alt="Guitar Body Shapes - King Electric Guitars"
              />
            </div>
          }
          textRight={
        <div>
          <h3>
            Everything From Scratch
          </h3>
          <p>
            I don't buy bodies. I'm building everything from scratch from rough wood I source from local lumber yards. All made by hand in my shop in Griffin, GA. I'm not cranking out body after body with a CNC machine. Every guitar is unique and made one at a time to your specifications.
          </p>
            
          <h2>
            Solid Body Shapes
          </h2>
          <p>
            No one can deny the strength of the classic designs. There are tons of companies essentially making clones of vintage guitars. They rarely stray far from those early designs. That's all well and good, but I want to push the envelope a bit. I want to see how these styles can evolve to be even better musical instruments than the classics.
          </p>
          <h3>
            T-Style Guitars
          </h3>
          <p>
            <a href="/t-style-guitar-taurus/">T-Style guitars</a> are popular for custom builders because they are simple designs. That simplicity is part of the guitar's genius. I, of course, also make a T-style guitar called the <a href="/t-style-guitar-taurus/">Taurus</a>. But I designed my t-style body to be a bit larger and a bit offset. I always loved T-style guitars, but being a larger guy, they always looked a little small and silly on me. And I'd heard similar from countless other players. My design corrects this and adds body contouring to make it more comfortable to play. I also refined the neck pocket to provide better playability high on the neck.
          </p>
        </div>
          }
        />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>
              S-Style Guitars
            </h3>
            <p>
              There is a reason the S-style design is so popular. They are the Swiss Army Knife of guitar designs. Because the electronics and pickups are attached to the pick guard, they can be configured any number of ways. The body contouring is more comfortable to play than the T-style. But, again, they tend to look a little small on larger players. I like the features of the S-Style, but there are so many versions of this body style I'm not sure I have anything to add. So, I'm working on a new design called The Space Dolphin. It's a Japanese influenced offset body. But it will have optional pick guard mounted electronics and optional S-style tremolo. All the flexibilty of the S-Style without the look.
            </p>
            <h3>
              <a href="/lp-style-guitar-futura/">LP-Style Guitars</a>
            </h3>
            <p>
              The <a href="/lp-style-guitar-futura/">LP style guitar</a> is a classic. There's no denying it. It's also the most copied body style around. If you ask a toddler to draw a guitar, I'm willing to bet they will draw something that looks like a LP. I love the style too, but I want to do something a little different with it. Keeping cool things like book-matched tops and sunbursts finishes, but a modified shape.
            </p>
            <p>
              I've just introduced the <a href="/lp-style-guitar-futura/">Futura</a> LP-Style guitar. Check it out, I think you'll like it.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
      /> 
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>
                Offset Guitars
              </h3>
              <p>
                I love offset guitars. Especially the Japanese guitar designs from the 60's. The era when all design seemed to revolve around cars and with an eye toward the space age. The Space Dolphin will draw styling cues from this era in design. It'll provide a flexible body design to work with any pickup configuration and any bridge. You don't see a lot of guitar builders coming out with new offset designs. And the 60's Japanese guitars were usually cheaply made. So, I hope to fill that gap for players who love the 60's offset style but want a higher quality, custom instrument.
              </p>
              <h3>
                Body Woods
              </h3>
              <p>
                I use a wide array of woods. Almost anything goes. I pride myself on spotting interesting options for guitars when I go to the lumber yards. I also have a pretty good stash of wood at my shop. There's mahogany that I like to use. Alder, Ash, and Poplar works great. I've done a few with Sapele. So I have a lot to choose from with body woods. I have a good variety of lumber on hand. I also have several vendor options to source special varieties for your dream guitar.
              </p>
              <p>
                Or I can source high dollar stuff as well. That makes a big difference in what you're gonna pay. Building guitars is a lot like when you're at the restaurant and the sign says "fish of the day, ask for price". 
              </p>
              <p>
                If you want it painted - or with a fabric top and painted back - then I'm going to suggest less pretty, less expensive wood. Do you want a smooth glass finish? Then something like Ash, while not impossible, takes lot more steps to get smooth. It takes a lot longer to fill the grain. So we may look at something like a Mahogany or Alder for that.
              </p>
              <p>
                When deciding on a body style, we have to take into consideration all your other choices. What type pickups do you want? What type hardware? Maple or darker Rosewood style fretboard? What scale length do you want the neck to be? Do you like a lighter or heavier bodied guitar? This all will influence what type of body style and wood we choose for your custom electric guitar. 
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/ethermaster-offset-body-jazzmaster-telecaster.jpg"
                alt="Offset Guitar Body Designs - The Ethermaster - Jazzmaster Telecaster hybrid"
              />
              <Blockquote>
                The Ethermaster is a jazzmaster style body shape made out of <a href="https://en.wikipedia.org/wiki/Sapele" target="_blank" rel="noreferrer">sapele</a> and <a href="https://en.wikipedia.org/wiki/Zebrawood" target="_blank" rel="noreferrer">zebrawood</a> stipes inspired by the early Kramer designs from the 70's. We went with the heavier wood to perfectly balance with the heavy aluminum Electrical Guitar Company bolt-on neck.
              </Blockquote>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarBody
